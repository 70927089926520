import styled from "styled-components";
import { isEmpty } from "lodash";
import TrModal from "components/TrModal";
import { Gap } from "components/Layout";

const getString = value => {
  if (Array.isArray(value)) {
    return value.join(" ");
  }
  return value;
};

const columnNameToDisplayName = {
  context: "Context",
  prompt: "Prompt",
  generatedText: "Target Output",
  "generatedText (predicted)": "GPT Output",
};

const disp = value => {
  return columnNameToDisplayName[value] || value;
};

const Table = styled.table`
  table-layout: fixed;
`;

const Container = styled.div`
  overflow-x: scroll;
`;

const Th = styled.th`
  text-align: left;
  font-weight: bold;
  padding: 20px 14px;
  border-bottom: 1px solid ${props => props.theme.color.darkGrey};
`;

const Td = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${props => (props.isNull ? props.theme.color.closer1 : props.theme.color.closest)};
  padding: 14px 14px;
  border-bottom: 1px solid ${props => props.theme.color.closest};
`;

const StyledTrModal = styled(TrModal)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const ContextCard = styled.div`
  padding: 10px;
  max-width: 80%;
  background-color: ${props => props.theme.color.closer1};
  white-space: pre-wrap;
  line-height: 1.2;
  overflow-y: auto;
  height: 200px;
`;

const PreviewSectionTitle = styled.div`
  border-top: 1px solid lightgrey;
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  padding: 8px 0;
`;

const TabularDatasetPreview = ({ columns, datasetPreview, blacklistColumns, noHeader }) => {
  if (isEmpty(datasetPreview)) {
    return "No results !";
  }

  const columnBlacklist = blacklistColumns || [];

  const pointWithMostColumns = datasetPreview.reduce((prevPoint, currentPoint) => {
    return Object.keys(currentPoint).length >= Object.keys(prevPoint).length ? currentPoint : prevPoint;
  });
  const rawColumnNames = columns || Object.keys(pointWithMostColumns);
  const columnNames = rawColumnNames.filter(e => !columnBlacklist.includes(e));

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            {!isEmpty(columnNames) &&
              columnNames.map(columnName => <Th key={columnName}>{noHeader ? "" : disp(columnName)}</Th>)}
          </tr>
        </thead>
        <tbody>
          {datasetPreview?.map((dataPoint, rowIndex) => {
            const dataPointContext = getString(dataPoint?.[columnNames?.[0]]);
            const dataPointPrompt = getString(dataPoint?.[columnNames?.[1]]);
            const dataPointGeneratedText = getString(dataPoint?.[columnNames?.[2]]);

            return (
              <StyledTrModal
                key={rowIndex}
                modalContent={
                  <div>
                    <PreviewSectionTitle style={{ marginTop: "-10px", borderTop: "none" }}>Context</PreviewSectionTitle>
                    <ContextCard>{dataPointContext}</ContextCard>
                    <Gap height="40px" />
                    <PreviewSectionTitle>Prompt</PreviewSectionTitle>
                    <div>{dataPointPrompt}</div>
                    <Gap height="40px" />
                    <PreviewSectionTitle>Target output</PreviewSectionTitle>
                    <div>{dataPointGeneratedText}</div>
                  </div>
                }
              >
                {columnNames.map((columnName, colIndex) => {
                  const val = Array.isArray(dataPoint[columnName])
                    ? dataPoint[columnName].join(" ")
                    : dataPoint[columnName];
                  return (
                    <Td
                      isNull={val === null}
                      key={`${rowIndex}-${val}-${colIndex}`}
                      style={{ width: `${100 / columnNames.length}%` }}
                    >
                      {val === null ? "null" : val}
                    </Td>
                  );
                })}
              </StyledTrModal>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default TabularDatasetPreview;
