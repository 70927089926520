import { errorTypes, getHandlingFromError } from "api/error-handling";
import * as backend from "api/backend/jobServiceEndpoints";
import * as projectServiceBackend from "api/backend/projectServiceEndpoints";
import { useState, useEffect } from "react";
import { last } from "lodash";

export const getTrainingOrganicProgressDataForJobId = async jobId => {
  const { data: logItems, error } = await backend.getJobOrganicMetricByJobId(jobId);

  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  let trainingIterationItems = [];
  try {
    trainingIterationItems = logItems.filter(logItem => logItem.includes('{"iter":')).map(item => JSON.parse(item));
  } catch {
    return {
      data: null,
      error: getHandlingFromError({
        errorType: errorTypes.GENERIC,
        message: "Faled to parse job metrics data",
      }),
    };
  }
  return { data: trainingIterationItems, error: null };
};

const EXAMPLE_DATA = [
  {
    TV: 230.1,
    radio: 37.8,
    newspaper: 69.2,
    sales: 22.1,
  },
  {
    TV: 44.5,
    radio: 39.3,
    newspaper: 45.1,
    sales: 10.4,
  },
  {
    TV: 17.2,
    radio: 45.9,
    newspaper: 69.3,
    sales: 9.3,
  },
  {
    TV: 151.5,
    radio: 41.3,
    newspaper: 58.5,
    sales: 18.5,
  },
];

export const getTrainingChainProgressDataForPipeline = async pipelineId => {
  const { data: logItems, error } = await projectServiceBackend.getPipelineTrainingLog(pipelineId);

  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  let trainingIterationItems = [];
  try {
    trainingIterationItems = logItems
      .filter(logItem => logItem.includes('{"iter":'))
      .map(item => {
        return { ...JSON.parse(item) };
      });
  } catch {
    return {
      data: null,
      error: getHandlingFromError({
        errorType: errorTypes.GENERIC,
        message: "Faled to parse job metrics data",
      }),
    };
  }
  return { data: { points: trainingIterationItems, finishTime: last(logItems)?.split(",")?.[0] }, error: null };
};

const useTrainingProgressForJobId = (jobId, pipelineId) => {
  const [iterationPoints, setIterationPoints] = useState([]);
  const [finishTime, setFinishTime] = useState("");

  const [error, setError] = useState(null);
  const [job, setJob] = useState("");

  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    clearInterval(intervalId);
    const pollIntervalId = setInterval(doFetchTrainingProgressAndJobStatus, 1000);
    setIntervalId(pollIntervalId);
    doFetchTrainingProgressAndJobStatus();

    return () => clearInterval(pollIntervalId);
  }, [jobId]);

  useEffect(() => {
    if (
      job?.status === "DONE" ||
      job?.status === "FAILED" ||
      job?.status === "KILLED" ||
      job?.status === "CANCELLED" ||
      !jobId
    ) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [job?.status]);

  const doFetchTrainingProgressAndJobStatus = async () => {
    if (!jobId) {
      return;
    }

    const { data: jobData, error: jobStatusError } = await backend.getTrainingJobById(jobId);
    setJob(jobData);
    if (jobStatusError) {
      setError(getHandlingFromError(jobStatusError));
    }

    const { data, error } = await getTrainingChainProgressDataForPipeline(pipelineId);
    setIterationPoints(data?.points);
    setFinishTime(data?.finishTime);
    setError(error);
  };

  return [iterationPoints, job, finishTime, error];
};

export default useTrainingProgressForJobId;
