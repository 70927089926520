import { zapiDelete, zapiGet, zapiPatch, zapiPost } from "api/zapi";

export const getDatasetById = async datasetId => {
  const { data, error } = await zapiGet(`/data-service/api/v1/datasets/${datasetId}`);
  return { data, error };
};

export const patchDataset = async (datasetId, fieldsToUpdate) => {
  const { data, error } = await zapiPatch(`/data-service/api/v1/datasets/${datasetId}`, {}, fieldsToUpdate);
  return { data, error };
};

export const deleteDataset = async datasetId => {
  const { data, error } = await zapiDelete(`/data-service/api/v1/datasets/${datasetId}`);
  return { data, error };
};

export const getDatasetPreviewById = async (datasetId, number = 20) => {
  const { data, error } = await zapiGet(`/data-service/api/v1/datasets/${datasetId}/preview`, { n: number });
  return { data, error };
};

export const generateDatasetProcessedPreview = async (datasetId, processConfig, number = 10) => {
  const { data, error } = await zapiPost(
    `/data-service/api/v1/datasets/${datasetId}/processing-preview`,
    { n: number },
    processConfig
  );
  return { data, error };
};

export const getDatasetInfoById = async datasetId => {
  const { data, error } = await zapiGet(`/data-service/api/v1/datasets/${datasetId}`);
  return { data, error };
};

export const getDataIngestionJob = async jobId => {
  const { data, error } = await zapiGet(`/data-service/api/v1/data-ingestion-jobs/${jobId}`);
  return { data, error };
};

export const uploadDataset = async (params, formDataBody) => {
  const { data, error } = await zapiPost(`/data-service/api/v1/data-ingestion-jobs/upload`, params, formDataBody);
  return { data, error };
};

export const ingestDataFromGoogleDriveFile = async (
  name,
  dataFormat,
  processingMethod,
  sharedWith,
  googleDriveFileId
) => {
  const { data, error } = await zapiPost("/data-service/api/v1/integrate/google-drive", {
    name,
    fileId: googleDriveFileId,
    format: dataFormat,
    method: processingMethod,
    sharedWith,
  });
  return { data, error };
};

export const ingestDataFromHubspotContacts = async (name, processingMethod, sharedWith) => {
  const { data, error } = await zapiPost("/data-service/api/v1/integrate/hubspot/contacts", {
    name,
    method: processingMethod,
    sharedWith,
  });
  return { data, error };
};

export const ingestDataFromSentenceLabelingTask = async (id, name) => {
  const { data, error } = await zapiPost(
    `/data-service/api/v1/integrate/sentence-labeling-task/${id}`,
    {
      name,
    },
    null
  );
  return { data, error };
};

export const getDatasetInfoByName = async datasetName => {
  const { data, error } = await zapiGet("/data-service/api/v1/datasets", { name: datasetName });
  return { data, error };
};

export const getDatasetsCreatedByUserName = async userName => {
  const { data, error } = await zapiGet("/data-service/api/v1/datasets", { created_by: userName });
  return { data, error };
};

export const getAllVisibleDatasets = async () => {
  const { data, error } = await zapiGet("/data-service/api/v1/datasets");
  return { data, error };
};

export const getTaskById = async taskId => {
  const { data, error } = await zapiGet(`/data-service/api/v1/tasks/${taskId}`);
  return { data, error };
};

export const addNewTask = async task => {
  const { data, error } = await zapiPost("/data-service/api/v1/tasks", {}, task);
  return { data, error };
};

export const getDataProcessingJob = async jobId => {
  const { data, error } = await zapiGet(`/data-service/api/v1/data-processing-jobs/${jobId}`);
  return { data, error };
};

export const postDataProcessingJob = async processingConfig => {
  const { data, error } = await zapiPost("/data-service/api/v1/data-processing-jobs", {}, processingConfig);
  return { data, error };
};

export const cloneDataset = async (datasetId, name) => {
  const { data, error } = await zapiPost(`/data-service/api/v1/datasets/${datasetId}/clone`, { name }, null);
  return { data, error };
};

export const getDatasetsFindByKey = async (params = {}) => {
  const { data, error } = await zapiGet("/data-service/api/v1/datasets/find-by-key", params);
  return { data, error };
};

export const postDataIngestionJobsExternalDb = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost("/data-service/api/v1/data-ingestion-jobs/external-db", params, body);
  return { data, error };
};
