import styled from "styled-components";
import { Edit, ExitToApp, Save, Telegram } from "@material-ui/icons";
import Tooltip from "components/ui/Tooltip";
import { UploadFileIcon } from "components/ui/Icons";
import FileInput from "components/ui/FileInput";

const TopBarContainer = styled.div`
  min-height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.color.closer0_5};
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  justify-content: flex-end;
  padding: 0px 10px;
`;

const HoverButtonContainer = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border: 0px solid ${props => props.theme.color.closest};
  border-radius: 60px;

  .MuiSvgIcon-root {
    fill: ${props => props.theme.color.closest};
    height: 24px;
    transition: none;
  }

  :hover {
    border: 0px solid ${props => props.theme.color.primary};
    .MuiSvgIcon-root {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const RightSideButtons = styled.div`
  display: flex;
`;

const LeftSideButtons = styled.div``;

const DatasetTopBar = ({
  isEditMode,
  onClickEdit,
  onClickCancel,
  onClickSave,
  onClickExecute,
  isSubmitDisabled,
  hasUserEditedPipeline,
}) => {
  if (isEditMode) {
    return (
      <TopBarContainer style={{ display: "grid", gridTemplateColumns: "1fr auto" }}>
        <LeftSideButtons style={{ justifySelf: "start" }}>
          <Tooltip title="Upload data">
            <HoverButtonContainer>
              <label>
                <UploadFileIcon />
                <input style={{ position: "absolute", opacity: 0, width: 0 }} type="file" />
              </label>
            </HoverButtonContainer>
          </Tooltip>
        </LeftSideButtons>
        <RightSideButtons>
          <Tooltip title="Cancel Changes">
            <HoverButtonContainer onClick={onClickCancel}>
              <ExitToApp />
            </HoverButtonContainer>
          </Tooltip>
          <Tooltip title="Save Changes">
            <HoverButtonContainer
              isDisabled={!hasUserEditedPipeline}
              onClick={onClickSave}
              data-tutorial-id="save-dataset-button"
            >
              <Save />
            </HoverButtonContainer>
          </Tooltip>
          <Tooltip title="Submit">
            <HoverButtonContainer isDisabled={isSubmitDisabled} onClick={onClickExecute}>
              <Telegram />
            </HoverButtonContainer>
          </Tooltip>
        </RightSideButtons>
      </TopBarContainer>
    );
  }

  return (
    <TopBarContainer style={{ display: "grid", gridTemplateColumns: "1fr auto" }}>
      <LeftSideButtons style={{ justifySelf: "start" }}>
        <Tooltip title="Upload data">
          <HoverButtonContainer>
            <label>
              <UploadFileIcon />
              <input style={{ position: "absolute", opacity: 0, width: 0 }} type="file" />
            </label>
          </HoverButtonContainer>
        </Tooltip>
      </LeftSideButtons>
      <RightSideButtons>
        <Tooltip title="Edit">
          <HoverButtonContainer onClick={onClickEdit} data-tutorial-id="edit-dataset-button">
            <Edit />
          </HoverButtonContainer>
        </Tooltip>
        <Tooltip title="Save Changes">
          <HoverButtonContainer isDisabled={!hasUserEditedPipeline} onClick={onClickSave}>
            <Save />
          </HoverButtonContainer>
        </Tooltip>
        <Tooltip title="Submit">
          <HoverButtonContainer isDisabled={isSubmitDisabled} onClick={onClickExecute}>
            <Telegram />
          </HoverButtonContainer>
        </Tooltip>
      </RightSideButtons>
    </TopBarContainer>
  );
};

export default DatasetTopBar;
