import Modal from "components/ui/Modal";
import { useState } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  padding: 20px;
  min-width: 600px;
  max-width: 1000px;
  max-height: 600px;
  overflow: auto;
`;

const TrModal = ({ className, children, modalContent = "", onClick = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <tr
        className={className}
        onClick={() => {
          onClick();
          setIsOpen(true);
        }}
      >
        {children}
      </tr>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>{modalContent}</ModalContent>
      </Modal>
    </>
  );
};

export default TrModal;
